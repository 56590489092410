<template>
  <div class="tab-header-in mt-0" v-bind="$attrs">
    <ul class="nav justify-content-center tab-round">
      <li class="nav-item" v-for="(tab, idx) in tabs">
        <a href="" class="nav-link" v-bind:class="{active: tabIdx == idx}" @click.prevent="changeTo(idx)" v-html="tab.title"></a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true
    },
    initTab: {
      type: String,
      default: ''
    }
  },
  data: function() {
    return {
      tabIdx: 0,
    }
  },
  mounted() {
    if ((this.tabIdx == 0) && (this.initTab != '')) {
      const newTabIdx = this.tabs.indexOf($.grep(this.tabs, (e) => {
        return e.id == this.initTab
      })[0])
      this.changeTo(newTabIdx)
    }
  },
  methods: {
    changeTo(idx) {
      this.tabIdx = idx
      this.$emit('change', idx)
    }
  }
}
</script>
